import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Page from 'components/layout/Page';
import { useI18n } from 'hooks/useI18n';
import { UIPublicManfredite } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { APP_URLS, getAppUrl, getPublicProfileUrl } from 'utils/app-urls';
import { config } from 'utils/config';
import PdfCVAbout from './about-me/PdfCVAbout';
import PdfCVCareer from './career/PdfCVCareer';
import PdfCVEducation from './education/PdfCVEducation';
import PdfCVHighlights from './highlights/PdfCVHighlights';
import PdfCVHeader from './PdfCVHeader';
import {
  StyledContainer,
  StyledFooter,
  StyledFooterLink,
  StyledFooterTitle,
  StyledIntro,
  StyledQR
} from './PublicProfilePdfComponent.styled';

type PublicProfilePdfComponentProps = {
  publicManfredite: UIPublicManfredite;
  slug?: string;
};

const PublicProfilePdfComponent: React.FC<PublicProfilePdfComponentProps> = ({
  publicManfredite
}) => {
  const { t } = useI18n('publicProfile');
  const { locale } = useRouter();

  const printed = useRef(false);

  const PRINT_DELAY_IN_MILLIS = 1500;

  const hasCareer =
    publicManfredite.timeline &&
    publicManfredite.timeline.filter((e) => e.cardType === 'career').length > 0;
  const hasEducation =
    publicManfredite.timeline &&
    publicManfredite.timeline.filter((e) => e.cardType === 'education').length >
      0;
  const hasCertifications =
    publicManfredite.timeline &&
    publicManfredite.timeline.filter((e) => e.cardType === 'certification')
      .length > 0;

  const hasHighlights =
    publicManfredite.timeline &&
    publicManfredite.timeline.filter(
      (e) =>
        e.cardType === 'highlight' && (e.type === 'AWARD' || e.type === 'POST')
    ).length > 0;

  const hasPublicProfile = !!publicManfredite.slug;

  const publicProfileUrl = publicManfredite.slug
    ? getPublicProfileUrl({
        slug: publicManfredite.slug,
        locale
      })
    : `${config.appUrl}${getAppUrl(APP_URLS.profilePreview, locale)}`;

  useEffect(() => {
    setTimeout(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const print = queryParams.get('print') === 'true';

      if (print && !printed.current) {
        printed.current = true;
        window.print();
      }
    }, PRINT_DELAY_IN_MILLIS);
  }, []);

  return (
    <Page noTopBar noFooter pageVariant="classicProfile">
      <StyledContainer>
        <StyledIntro>
          <PdfCVHeader
            publicManfredite={publicManfredite}
            publicProfileUrl={publicProfileUrl}
            hasPublicProfile={hasPublicProfile}
          />
          <PdfCVAbout publicManfredite={publicManfredite} />
        </StyledIntro>

        {hasCareer && <PdfCVCareer publicManfredite={publicManfredite} />}

        {(hasEducation || hasCertifications) && (
          <PdfCVEducation
            publicManfredite={publicManfredite}
            publicProfileUrl={publicProfileUrl}
          />
        )}

        {hasHighlights && (
          <PdfCVHighlights
            publicManfredite={publicManfredite}
            publicProfileUrl={publicProfileUrl}
          />
        )}

        {hasPublicProfile && (
          <StyledFooter>
            <StyledQR>
              <QRCode value={publicProfileUrl} quietZone={0} />
            </StyledQR>
            <div>
              <StyledFooterTitle>
                <Trans
                  ns="publicProfile"
                  i18nKey="pdf.qrCode.title"
                  components={{
                    strong: <strong />,
                    br: <br />
                  }}
                />
              </StyledFooterTitle>
              <p>{t('pdf.qrCode.desc')}</p>
              <StyledFooterLink>
                <FontAwesomeIcon icon={faPlusCircle} />
                <span>
                  <Trans
                    ns="publicProfile"
                    i18nKey="pdf.qrCode.link"
                    components={{
                      profileLink: (
                        <a
                          href={publicProfileUrl}
                          target="_blank"
                          rel="noreferrer"
                        />
                      )
                    }}
                  />
                </span>
              </StyledFooterLink>
            </div>
          </StyledFooter>
        )}
      </StyledContainer>
    </Page>
  );
};

export default PublicProfilePdfComponent;
